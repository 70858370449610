import {createMuiTheme} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#101ba5',
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>
            <>
                <CssBaseline/>
                <App/>
            </>
        </SnackbarProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
