import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, {ReactNode} from 'react';
import AtrLogo from '../assets/atr-logo.png';
import HeaderBackground from '../assets/header-background.jpg';

const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
        background: `
            linear-gradient(45deg, rgba(16, 27, 165, 0.75) 0%, rgba(27, 165, 65, 0.75) 100%),
            url('${HeaderBackground}') no-repeat center/cover,
            #000
        `,
        height: 350,
        paddingTop: 20,
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            height: 60,
            paddingTop: 10,
        },
    },
    logo: {
        display: 'block',
        margin: '0 auto',
        height: 80,

        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
            height: 40,
        },
    },
    title: {
        textAlign: 'center',
        color: 'white',
        marginTop: 90,

        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 15,
            right: 10,
            marginTop: 0,
            fontSize: theme.typography.h5.fontSize,
        },
    },
    container: {
        paddingBottom: theme.spacing(2),
    },
}));

type Props = {
    title ?: string;
    children : NonNullable<ReactNode>;
};

const Layout : React.FC<Props> = ({title, children} : Props) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.header}>
                <a href="https://www.atrinternational.com/">
                    <img src={AtrLogo} alt="ATR International" className={classes.logo}/>
                </a>

                {title && (
                    <Typography className={classes.title} variant="h3">
                        {title}
                    </Typography>
                )}
            </div>

            <Container className={classes.container}>
                {children}
            </Container>
        </>
    );
};

export default Layout;
